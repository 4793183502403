
  import userController from '/home/builduser/work/be92b78070518c73/packages/client/crypto-nft-asset-page/src/components/AssetPage/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://98c3c7d2d05f4d6ca69711656fecf3ba@sentry.wixpress.com/2501',
    id: '98c3c7d2d05f4d6ca69711656fecf3ba',
    projectName: 'crypto-nft-asset-page',
    teamName: 'crypto',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/be92b78070518c73/packages/client/crypto-nft-asset-page/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.tabs.layout":"Layout","app.settings.tabs.setting":"Settings","app.settings.tabs.text":"Text","app.settings.defaults.greetingsPretext":"to","app.settings.defaults.buyNowButtonText":"Buy Now","nft.itemPage.checkout.royalty.label":"{{percentage}} royalty","nft.itemPage.navigation.next":"Next","nft.itemPage.details.header":"Details","nft.itemPage.thankYou.continueBrowsing.button":"Continue Browsing","nft.itemPage.details.blockchain.label":"Blockchain ","nft.itemPage.sold.label":"Sold","nft.itemPage.royalty.label":"{{percentage}} royalty","nft.itemPage.details.blockchain.ethereum":"Ethereum","nft.itemPage.thankYou.title":"Thank you for your purchase!","nft.itemPage.thankYou.subtitle":"It will be confirmed in the next few minutes.","nft.itemPage.buyNow.button":"Buy Now","nft.itemPage.checkout.title":"Checkout","nft.itemPage.navigation.previous":"Prev","nft.itemPage.details.metadata.label":"Metadata","nft.itemPage.details.tokenStandard.label":"Token standard","nft.itemPage.checkout.termsCheckbox.text":"By checking this box, I agree to {{websiteName}}'s {{termsName}}.","nft.itemPage.termsModal.back.button":"Back to Checkout","nft.itemPage.details.tokenID.label":"Token ID","nft.itemPage.properties.header":"Properties","nft.itemPage.details.contractAddress.label":"Contract address","nft.itemPage.checkout.confirm.button":"Confirm Payment","nft.itemPage.currency.tooltip.eth":"ETH","nft.itemPage.details.metadata.centralized":"Centralized","nft.itemPage.thankYou.viewTransaction.button":"View Transaction","nft.itemPage.ownerAddress.label":"Owner address:","nft.itemPage.noItemsFound.text":"This item couldn't be found.","nft.itemPage.noItemsFound.continueBrowsing.link":"Continue Browsing"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/be92b78070518c73/packages/client/crypto-nft-asset-page/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix NFT",
    componentName: "AssetPage",
    appDefinitionId: "ffaa7766-f73b-4383-8661-c1514a0c1af9",
    componentId: "3ef6158e-70cd-443e-b4a2-dc61e5f18d3b",
    projectName: "crypto-nft-asset-page",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/be92b78070518c73/packages/client/crypto-nft-asset-page/src/components/AssetPage/controller.ts",
  };

  export const controller = _controller
  export default controller;
