import {CreateControllerFn} from '@wix/yoshi-flow-editor';
import {AssetPageStore} from './Widget/Store/AssetPageStore';

const createController: CreateControllerFn = async ({controllerConfig, flowAPI: {settings, httpClient}}) => {
  let assetPageStore: AssetPageStore;
  const {
    platformAPIs: {storage, bi},
    appParams: {instance},
    wixCodeApi: {location, window, site},
    config,
  } = controllerConfig;

  return {
    async pageReady() {
      assetPageStore = new AssetPageStore(
        controllerConfig.setProps,
        httpClient,
        site,
        storage,
        instance,
        bi!.metaSiteId,
        window,
        location,
        settings,
        config.style.styleParams
      );

      await assetPageStore.setInitialState();
      controllerConfig.setProps(await assetPageStore.toProps());
    },
  };
};

export default createController;
