import type {IHttpClient} from '@wix/fe-essentials/http-client';
import {DigitalAssetsService} from '../../../../services/digitalAssetsService';
import {ILocation, ISiteApis, IWixWindow, IWebStorage} from '@wix/yoshi-flow-editor';
import {CollectionsService} from '../../../../services/collectionsService';
import {Collection} from '@wix/ambassador-crypto-nft-v1-collection/types';
import {CollectionOrigin, DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {LastConnectedWalletService, NavigationPath, NavigationService} from '@wix/crypto-nft-view-sdk';
import _ from 'lodash';

export class AssetPageStore {
  private digitalAssetsService: DigitalAssetsService;
  private collectionsService: CollectionsService;
  private navigationService: NavigationService;
  private lastConnectedWalletService: LastConnectedWalletService;
  private digitalAsset: DigitalAsset = {};
  private collection: Collection = {};
  private isMobile: boolean;
  private isModalOpened = false;
  private isEmptyState = false;
  private galleryUrl: string = '';
  private navigationPath: NavigationPath[] = [];
  private assetPageUrl: string = '';

  constructor(
    private readonly updateComponent: (props: {[key: string]: any}) => void,
    private readonly httpClient: IHttpClient,
    private readonly siteApi: ISiteApis,
    private readonly storage: {local: IWebStorage},
    private readonly instance: string,
    private readonly metaSiteId: string,
    private readonly window: IWixWindow,
    private readonly location: ILocation,
    private readonly settingsGetter: {get: Function}, // TBD - install ISettingsGetter type
    private readonly styleParams: any // TBD - install ISettingsGetter type
  ) {
    this.digitalAssetsService = new DigitalAssetsService(this.httpClient);
    this.collectionsService = new CollectionsService(this.httpClient);
    this.navigationService = new NavigationService(this.storage, this.location, this.siteApi);
    this.lastConnectedWalletService = new LastConnectedWalletService(this.storage);
    this.isMobile = this.window && this.window.formFactor && this.window.formFactor === 'Mobile';
  }

  private async copyToClipboard(text: string) {
    (this.window as any).copyToClipboard && (this.window as any).copyToClipboard(text);
  }

  private setEmptyState(): void {
    this.isEmptyState = true;
    this.updateProps();
  }

  private isEditor() {
    return this.window && this.window.viewMode && this.window.viewMode !== 'Site';
  }

  private isSsr(): boolean {
    return this.window && this.window.rendering.env === 'backend';
  }

  private async fetchDigitalAsset(): Promise<DigitalAsset | undefined> {
    if (this.isEditor()) {
      return this.digitalAssetsService.fetchDefaultAssetForEditor();
    }
    const assetId = this.location.path.length > 1 && this.location.path[1];
    if (assetId) {
      return this.digitalAssetsService.fetchAsset(assetId);
    }
    return undefined;
  }

  private async getDigitalAssetCollection(digitalAsset: DigitalAsset): Promise<Collection | undefined> {
    if (digitalAsset.collectionOrigin === CollectionOrigin.WIX) {
      const wixCollections = await this.collectionsService.fetchWixCollections();
      return wixCollections?.find((c) => c.id && c.id === digitalAsset.collectionId);
    }
    return this.collectionsService.fetchUserCollection(digitalAsset.collectionId!);
  }

  public async setInitialState(): Promise<any> {
    this.galleryUrl = await this.navigationService.getGalleryUrl();
    this.assetPageUrl = await this.navigationService.getAssetPageUrl();
    this.navigationPath = await this.navigationService.getNavigationPathForAssetPage();

    const digitalAsset = await this.fetchDigitalAsset();

    if (!digitalAsset || !digitalAsset.collectionId) {
      this.setEmptyState();
      return;
    }

    const collection = await this.getDigitalAssetCollection(digitalAsset);
    if (!collection) {
      this.setEmptyState();
      return;
    }

    this.digitalAsset = digitalAsset;
    this.collection = collection;
  }

  private onCloseModalClicked() {
    this.isModalOpened = false;
    this.updateProps();
  }

  private onBuyNowButtonClicked() {
    this.isModalOpened = true;
    this.updateProps();
  }

  private onContinueShoppingClicked() {
    this.navigationService.navigateToUrl(this.galleryUrl);
  }

  private onPrevNextClicked(url: string) {
    this.navigationService.navigateToUrl(url);
  }

  private onNavigationBreadcrumClicked(url: string) {
    this.navigationService.navigateToUrl(url);
  }

  private updateProps() {
    this.updateComponent(this.toProps());
  }

  private get imageUrl() {
    const id = this.digitalAsset?.mediaItem?.image?.id;
    if (!id) {
      return null;
    }

    return `https://static.wixstatic.com/media/${id}`;
  }

  public toProps() {
    return {
      isModalOpened: this.isModalOpened,
      isEmptyState: this.isEmptyState,
      isSsr: this.isSsr(),
      isMobile: this.isMobile,
      digitalAsset: this.digitalAsset,
      imageUrl: this.imageUrl,
      collection: this.collection,
      galleryUrl: this.galleryUrl,
      navigationPath: this.navigationPath,
      assetPageUrl: this.assetPageUrl,
      instance: this.instance,
      metaSiteId: this.metaSiteId,
      isSold: this.digitalAsset?.quantity?.sold === this.digitalAsset?.quantity?.total,
      navigationAssetIds: this.navigationService.getNavigationAssetIds(),
      copyToClipboard: this.copyToClipboard.bind(this),
      onCloseModalClicked: this.onCloseModalClicked.bind(this),
      onBuyNowButtonClicked: this.onBuyNowButtonClicked.bind(this),
      onContinueShoppingClicked: this.onContinueShoppingClicked.bind(this),
      onNavigationBreadcrumClicked: this.onNavigationBreadcrumClicked.bind(this),
      onPrevNextClicked: this.onPrevNextClicked.bind(this),
      shouldShowPropertiesTab: this.digitalAsset?.properties && !_.isEmpty(this.digitalAsset?.properties),
      lastConnectedWallet: this.lastConnectedWalletService.getLastConnectedWalletKey(),
      setLastConnectedWalletKey: this.lastConnectedWalletService.setLastConnectedWalletKey.bind(this),
      removeLastConnectedWalletKey: this.lastConnectedWalletService.removeLastConnectedWalletKey.bind(this),
    };
  }
}
