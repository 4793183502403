import type {IHttpClient} from '@wix/fe-essentials/http-client';
import {DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {getDigitalAsset, queryDigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/http';

export class DigitalAssetsService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async fetchAsset(id: string): Promise<DigitalAsset | undefined> {
    try {
      const {
        data: {digitalAsset},
      } = await this.httpClient.request(getDigitalAsset({digitalAssetId: id}));
      return digitalAsset;
    } catch {
      return undefined;
    }
  }

  public async fetchDefaultAssetForEditor(): Promise<DigitalAsset | undefined> {
    try {
      const {
        data: {digitalAssets},
      } = await this.httpClient.request(queryDigitalAsset({query: {paging: {limit: 1}}}));
      if (digitalAssets && digitalAssets.length) {
        return digitalAssets[0];
      }
    } catch {
      return undefined;
    }
  }
}
