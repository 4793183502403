import type {IHttpClient} from '@wix/fe-essentials/http-client';
import {Collection, QueryCollectionResponse} from '@wix/ambassador-crypto-nft-v1-collection/types';
import {getUserCollection, getWixCollections} from '@wix/ambassador-crypto-nft-v1-collection/http';

export class CollectionsService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async fetchWixCollections(): Promise<QueryCollectionResponse['collections']> {
    const {data} = await this.httpClient.request(getWixCollections({}));
    return data.collections;
  }

  public async fetchUserCollection(collectionId: string): Promise<Collection | undefined> {
    const {
      data: {collection},
    } = await this.httpClient.request(getUserCollection({collectionId}));
    return collection;
  }
}
